export const Bio = {
    name: "Amit Korat",
    // image: "https://avatars.githubusercontent.com/u/84698174?v=4",
    image: "https://raw.githubusercontent.com/Amit-korat/images/refs/heads/main/HeroImage2.png",
    roles: ["Full Stack Developer.", "Programmer.", "Competitive Coder."],
    description:
        "I am a motivated and versatile individual, always eager to take on new challenges. With a passion for learning I am dedicated to delivering high-quality results. With a positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things.",
    github: "https://github.com/Amit-korat",
    resume: "https://drive.google.com/file/d/1J7jYOH0L8EbbFWbRSXvpk87o1kaX-PqI/view?usp=sharing",
    linkedin: "https://www.linkedin.com/in/amit-korat/",
    twitter: "https://x.com/AmitKorat5",
    insta: "https://www.instagram.com/amit.korat_/",
};

export const skills = [
    {
        title: "Frontend",
        skills: [
            // {
            //     name: "Next Js",
            //     image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACTklEQVR4Ab1XAaQqURB9DyohSykREpRIQSAlBCoECKUFCSRCBBEAaSEABQEoCIEASCwAUICALgCo83do0//9v819XX845O7VnDkzOzP7JWGaBd3C3IJpQVjAHeJ+Rs9a97vKLGrBsB1KgMhEP3FMUUwt4ENMfxr1yQIU4SSjRkbeOZtERmHk6pXQVDlnkHh9S+QLTm1hkiz4n/gzFQuny9FoFLquE+i34x+n02k0m00UCoV3BIzn3MMJrVYLtp1OJ0cS/X4f5/MZhmG8IyDsWtDfEaDIn2232/3zbrvdxuFwwGg04qRBt+VnETBNE0IIkE2n07/erdfrWK/X6Ha73Hb9ZXII3G43ivy3dNRqtZe7lUoFs9mM6oBDwCQCgquALT1FT3a5XF7qIZ/PYzgcolqtcggIIgBZAgRKB6lCRalp2uM8k8mAVMrlchwC+DEBipycE4n5fP44j8ViKJVKSCaTbAJCpgaez4vFIsjoWa/XA50FAgEkEgmEw2F2CkxZBZ5Br5tt1ITcbjd8Ph88Hg+7CBefECCsVitS4aVJcV9D/VMCVITk/Hq9YrPZyBBo2a1YMGvAcQYcj0cCtWMugcdYNhjDiBrP25mx3++x3W6RzWZZ8isfxzQLlsslJpMJpYY5jhkqcOH1ejEYDDAej9FoNOByuZxGsfqVzC7KTqcDSkkqleKsZOqX0mAwiHK5DGrJfr+fs5SqX8sjkQji8ThCoRC+v78Za7l6JagrUh3YkUuZpqgwDaecc9VYSDoV5Fg+at7n+eLN57kuE/EvzHr/Kvs31aYAAAAASUVORK5CYII=",
            // },
            {
                name: "React Js",
                image: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
            },
            // {
            //     name: "Redux",
            //     image: "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
            // },
            {
                name: "JavaScript",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
            },
            {
                name: "TypeScript",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/2048px-Typescript_logo_2020.svg.png",
            },
            {
                name: "Tailwind CSS",
                image: "https://files.raycast.com/nwt9ncojkvwmjfkaada8upafvpnu",
            },
            // {
            //     name: "shadcn/ui",
            //     image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6hcl9s0d3s9UYqHPvSpvTOZwngO4_pARCYHkCWmnFLQ&s",
            // },
            {
                name: "Angular",
                image: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Angular_full_color_logo.svg",
            },
            {
                name: "Material UI",
                image: "https://mui.com/static/logo.png",
            },
            {
                name: "HTML",
                image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
            },
            {
                name: "CSS",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/CSS3_logo.svg/800px-CSS3_logo.svg.png",
            },
            {
                name: "Bootstrap",
                image: "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
            },
        ],
    },
    {
        title: "Backend",
        skills: [
            {
                name: "Node Js",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original.svg",
            },
            {
                name: "Express Js",
                image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAbFBMVEX////8/Pz09PT19fX4+Pjd3d2ampoAAAAzNDWoqKno6Oh0dHRcXFzIycm5ubktLi4hIiN7e3yLi4tmZ2fi4uI9Pj7S0tIHCQuzs7NJSkru7u6goKB+f3/W1taNjo69vb1PT1BsbW0VFRc3ODhehn9TAAAA30lEQVR4Ad3QhXHEMBBA0W8QmAVmTq7/HjOeDdSQewNiLfBukjRNM4A0TeCR/0wSpY0xtiihMgWPsraNnLWd8yFEE3vyOIwAeprhMQ5Lm8O6uT3hWPwKjQspj+zsLvlBuwNmV1DaukS+t3sqs8MWkAU7ejMiGnMhkjoCqnZmQ3CZPwM8Hy/8Hd7xF7BWzjQIxulCsJaQbG6r7xXR2zNHrn1s0AwhUdNPjunejTLxTlHedS89EOMwFAkobzR4ewFptRyI9tPY18uZkFIYzUO5akWU22BflYKx2xGX1Rn/yxf+uwzyBt/vSwAAAABJRU5ErkJggg==",
            },
            {
                name: "MongoDB",
                image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
            },
            {
                name: "MySQL",
                image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
            },
            {
                name: "Postgresql",
                image: "https://www.postgresql.org/media/img/about/press/elephant.png",
            },
            {
                name: "Firebase",
                image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
            },
            /*  {
                name: "Python",
                image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
            }, */
        ],
    },
    /* {
        title: "Android",
        skills: [
            {
                name: "Java",
                image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
            },
            {
                name: "Kotlin",
                image: "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
            },
            {
                name: "XML",
                image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBMw6_RdwKQ9bDFfnKDX1iwMl4bVJEvd9PP53XuIw&s",
            },
            {
                name: "Android Studio",
                image: "https://developer.android.com/static/studio/images/new-studio-logo-1_1920.png",
            },
        ],
    }, */
    {
        title: "Programming Languages",
        skills: [
            {
                name: "C/C++",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1822px-ISO_C%2B%2B_Logo.svg.png",
            },
            {
                name: "Java",
                image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
            },
            {
                name: "Python",
                image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
            },
            {
                name: "PHP",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/2560px-PHP-logo.svg.png",
            },
        ],
    },
    {
        title: "Others",
        skills: [
            {
                name: "Git",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png",
            },
            {
                name: "GitHub",
                image: "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
            },
            {
                name: "Docker",
                image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
            },
            {
                name: "Selenium",
                image: "https://static-00.iconduck.com/assets.00/selenium-icon-2048x1986-3fr2ikf8.png",
            },
            {
                name: "AWS",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-plain-wordmark.svg",
            },
            {
                name: "Postman",
                image: "https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/postman-icon.png",
            },
            {
                name: "Shopify",
                image: "https://img.icons8.com/?size=100&id=uSHYbs6PJfMT&format=png&color=000000",
            },
            {
                name: "Figma",
                image: "https://cdn.sanity.io/images/599r6htc/localized/46a76c802176eb17b04e12108de7e7e0f3736dc6-1024x1024.png?w=804&h=804&q=75&fit=max&auto=format",
            },
            {
                name: "Vercel",
                image: "https://assets.vercel.com/image/upload/front/favicon/vercel/180x180.png",
            },
        ],
    },
];

export const experiences = [
    {
        id: 0,
        img: "https://raw.githubusercontent.com/Amit-korat/images/main/technonite-logo.png",
        role: "Software Development Engineer",
        company: "Technonite",
        date: "Dec 2023 - Present",
        desc: "Developed, Designed and implemented interactive and responsive user interfaces using React JS, enhancing the overall user experience. Developed and optimized RESTful APIs with Node.js and Express.js, facilitating efficient data retrieval and manipulation. Collaborated with cross-functional teams and followed Agile practices and conducted performance optimizations and resolved issues.",
        skills: [
            // "Next Js",
            "React Js",
            "Express Js",
            "Node Js",
            "JavaScript",
            "TypeScript",
            "Git",
            "GitHub",
            "Agile",
        ],
        // doc: "https://drive.google.com/file/d/1im7HxcXBygHqY-tpGSKAZ19k9pH-QEA-/view?usp=sharing",
    },
    {
        id: 3,
        img: "https://raw.githubusercontent.com/Amit-korat/images/main/Mindex-logo.png",
        role: "Web Developer",
        company: "Mindex Infotech",
        date: "Aug 2023 - Nov 2023",
        desc: "As a Web Developer, Designed and implemented interactive and responsive user interfaces using JavaScript, enhancing the overall user experience with PHP. Learn Back-end development and Database Management, facilitating efficient data retrieval and manipulation. Collaborated with cross-functional teams and followed Agile practices and conducted performance optimizations and resolved many issues.",
        skills: [
            "PHP",
            "Team Management",
            "Project Coordination",
            "Community Building",
            "Database management",
            "Problem-Solving",
            "Marketing and Promotion",
        ],
    },
    /* {
        id: 0,
        img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/neurobit.jpeg?alt=media&token=1cacbb7b-e5ea-4efb-9b3d-f5a31b9564ad",
        role: "Frontend Engineer Intern",
        company: "Neurobit",
        date: "June 2023 - Present",
        desc: "Working on the frontend of the web application using ReactJS, Redux, and Material UI.",
        skills: ["ReactJS", "Redux", "NodeJs", "Material UI", "HTML", "CSS", "JavaScript"],
        doc: "https://firebasestorage.googleapis.com/v0/b/buckoid-917cf.appspot.com/o/Screenshot%20from%202023-05-28%2023-20-46.png?alt=media&token=5570f995-e8f4-4f12-bb2f-73bcf4b20730",
    }, */
];

export const education = [
    // {
    //     id: 0,
    //     img: "https://alpha-stark.github.io/images/NortheasternUniversityLogo.jpg",
    //     // img: "https://www.canamgroup.com/assets/universitylogos/1691388731-Northeastern-University-Boston-Campus.webp",
    //     school: "Khoury College of Computer Sciences, Northeastern University, Boston",
    //     date: "Sep 2024 - Sep 2026",
    //     grade: " /4 GPA",
    //     desc: "I'll be joining Khoury College of Computer Sciences at Northeastern University, Boston, Massachusetts for my Master's degree in Computer Science.",
    //     degree: "Master of Science in Computer Science",
    // },
    {
        id: 1,
        img: "https://raw.githubusercontent.com/Amit-korat/images/main/PPSavaniUniversityLogo.jpeg",
        school: "P P Savani University, Surat",
        date: "July 2020 - June 2024",
        grade: "8.29 CGPA",
        desc: "I have completed a Bachelor's degree in Computer Engineering at P P Savani University, Surat. I have taken courses in Data Structures & Algorithms, Design & Analysis of Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others. I was also a member of the Google Developers Student Club (GDSC) at PPSU, where I lead and worked on exciting projects with a team of talented developers, along with mentoring over 800+ students.",
        degree: "Bachelor of Technology - BTech, Computer Science and Engineering",
    },
    {
        id: 2,
        img: "https://raw.githubusercontent.com/Amit-korat/images/main/CFE.png",
        school: "P P SAVANI CENTER FOR EXCELLENCE, Surat",
        date: "Apr 2018 - Apr 2020",
        grade: "67 PR",
        desc: "I completed my class 12 high school education at PP Savani Center for Excellence, Surat, where I studied Science with Computer Science.",
        degree: "GSEB(XII), Science with Computer",
    },
    {
        id: 3,
        img: "https://raw.githubusercontent.com/Amit-korat/images/main/ppsv.png",
        school: "P P Savani Vidyabhavan, Surat",
        date: "Apr 2017 - Apr 2018",
        grade: "82.84 PR",
        desc: "I completed my class 10 education at P P Savani Vidyabhavan, Surat, where I studied Science with Computer Application.",
        degree: "GSEB(X), Science with Computer",
    },
];

export const projects = [
    {
        id: 0,
        title: "Evently",
        date: "Dec 2023 - March 2024",
        description:
            "Evently is a comprehensive, full-stack events management platform built on Next.js 14, featuring seamless payment processing through Stripe, user authentication with Clerk, and robust functionalities for creating, reading, updating, and deleting events, ultimately providing users with a dynamic and engaging event experience.",
        image: "https://alpha-stark.github.io/images/Evently.png",
        tags: [
            "Next Js",
            "React Js",
            "MongoDb",
            "Node Js",
            "Express Js",
            "Stripe",
            "Tailwind CSS",
        ],
        category: "web app",
        github: "https://github.com/Amit-korat",
        webapp: "https://alpha-evently.vercel.app/",
        // member: [
        //     {
        //         name: "Amit Korat",
        //         img: "https://raw.githubusercontent.com/Amit-korat/images/refs/heads/main/HeroImage2.png",
        //         linkedin: "https://www.linkedin.com/in/amit-korat/",
        //         github: "https://github.com/Amit-korat",
        //     },
        // ],
    },
    {
        id: 1,
        title: "Data Fort",
        date: "Aug 2023",
        description:
            "Aims to detect crop disease via drone images throught ML Model, supported by blockchain for photo storage and Ethereum Smart Contracts for decentralization.",
        image: "https://alpha-stark.github.io/images/DataFort.png",
        // image: "https://image.architonic.com/pro1-3/20182762/ps-1440-z-pro-sq-arcit18.jpg",
        tags: ["JavaScript", "Python", "Solidity", "TypeScript"],
        category: "machine learning",
        github: "https://github.com/Amit-korat",
        webapp: "https://datafort-1761ff.spheron.app/",
        // member: [
        //     {
        //         name: "Amit Korat",
        //         img: "https://raw.githubusercontent.com/Amit-korat/images/refs/heads/main/HeroImage2.png",
        //         linkedin: "https://www.linkedin.com/in/amit-korat/",
        //         github: "https://github.com/Amit-korat",
        //     },
        // ],
    },
    {
        id: 2,
        title: "Promptopia",
        date: "Nov 2023 - Dec 2023",
        description:
            "Promptopia serves as an open-source prompting tool, enabling users to discover, craft, and share creative prompts effortlessly.",
        image: "https://alpha-stark.github.io/images/Promptopia.png",
        tags: [
            "React Js",
            "MongoDb",
            "Node Js",
            "Express Js",
            "Redux",
            "Tailwind CSS",
        ],
        category: "web app",
        github: "https://github.com/Alpha-Stark/Promptopia",
        webapp: "https://alpha-promptopia.vercel.app/",
    },
    {
        id: 3,
        title: "GitHub Profile Finder",
        date: "Oct 2023 - Nov 2023",
        description:
            "App to explore GitHub users & their profiles for efficient searches & info display.",
        image: "https://raw.githubusercontent.com/Amit-korat/images/refs/heads/main/%20profile-finder.png",
        tags: [
            "React Js",
            "Node Js",
            "Express Js",
            "GitHub API",
            "Tailwind CSS",
        ],
        category: "web app",
        github: "https://github.com/Amit-korat/Github-Finder",
        webapp: "https://github-finder-amit-korat.vercel.app/",
    },
    {
        id: 4,
        title: "apisculptify",
        date: "Aug 2024 - Sep 2024",
        description:
            "Apisculptify is a powerful Node.js tool designed to automate the process of generating RESTful APIs for your TypeORM models.",
        image: "https://raw.githubusercontent.com/Amit-korat/images/refs/heads/main/apisculptify.png",
        tags: ["MongoDb", "Node Js", "Type Script", "Express Js", "Swagger"],
        category: "npm package",
        github: "https://github.com/Amit-korat/apisculptify",
        webapp: "https://www.npmjs.com/package/apisculptify",
    },
    {
        id: 5,
        title: "Kayli Fashion (Client's)",
        date: "jun 2024 - jul 2024",
        description:
            "KAYLI is synonymous with luxury, traditional Indian craft and a distinct and refreshing design. Representing rooted culture and crafts of India.",
        image: "https://raw.githubusercontent.com/Amit-korat/images/refs/heads/main/kayli-fashion.png",
        tags: ["Liquid", "shopify tools", "CSS"],
        category: "web app",
        github: "https://github.com/Amit-korat",
        webapp: "https://kaylifashion.com/",
    },
];

/* export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
 */
